import logo from './logo.svg';
import './App.css';
import {
  Route,
  Routes,
  HashRouter as Router  // Alteração aqui
} from 'react-router-dom';
import Home from './pages/Home/Home';
import Page from './pages/Page/Page';
import Redirect from './pages/Redirect/Redirect';
import RedirectFast from './pages/RedirectFast/RedirectFast';
import RenderSite from './pages/RenderSite/RenderSite';
import TypebotIntegration from './pages/TypebotIntegration/TypebotIntegration';
import TypebotIntegrationReact from './pages/TypebotIntegrationReact/TypebotIntegrationReact';
import Redirect301 from './pages/Redirect301/Redirect301';

import { Popup } from '@typebot.io/react'

function App() {
  return (
    <Router>
      <div className="App" style={{height:'100vh'}}>
        <Routes> {/* com hash routes = https://google.com/#/page */}
          {/* <Route path="/page" element={<Page title="congue felis, et ornare congue felis, et ornar congue felis, et ornar" subtitle={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris feugiat vestibulum venenatis"} button={"FDSAF FDISAF IUDJF"} />} /> */}
          {/* <Route path="/home2" element={<Home />} /> */}
          {/* <Route path="/" element={<RedirectFast site='https://typebot.io/arthur22' />} /> */}
          {/* <Route path="/" element={<Redirect301  site='https://typebot.io/arthur22' />} /> */}
          <Route path="/" element={<RenderSite site='https://typebot.io/andressa-siquel' />} />
          {/* <Route path="/" element={<TypebotIntegration codebot='arthur22' />} /> */}
          {/* <Route path="/" element={<TypebotIntegrationReact codebot='arthur22' />} /> */}

          
          {/* <Route path="/" element={<Page title="Criação de Sites Profissionais: Soluções Práticas e Acessíveis para Empresas. 🚀🔥" subtitle={"Desenvolva seu projeto de site com nossa equipe de designers e programadores experientes e capacitados para entender suas necessidades e criar a melhor solução personalizada.👇"} button={"Clique aqui"} link="" />} /> */}


          {/* <Route path="/superwin" element={<Page title="Transforme suas finanças com eficácia: Descubra as estratégias essenciais para dominar de uma vez por todas o controle total da sua vida financeira. 🚀🔥" subtitle={"Entre em contato conosco pelo botão de whatsapp logo abaixo para saber mais e conferir os resultados reais👇"} button={"chame-nos no Whatsapp"} link="whatsapp://api.whatsapp.com/send?phone=558196507193&text=Oii%20quero%20o%20bot" />} /> */}

          {/* <Route path="/" element={<Page title="Transforme suas finanças com eficácia: Descubra as estratégias essenciais para dominar de uma vez por todas o controle total da sua vida financeira. 🚀🔥" subtitle={"Entre em contato conosco pelo botão de whatsapp logo abaixo para saber mais e conferir os resultados reais👇"} button={"chame-nos no Whatsapp"} link="whatsapp://api.whatsapp.com/send?phone=558196507193&text=Oii%20quero%20o%20bot" />} /> */}
          {/* <Route path="/" element={<Redirect site='https://api.whatsapp.com/send?phone=558196507193&text=Oi%20quero%20saber%20mais' />} /> */}
        
        
        </Routes>
      </div>
    </Router>
  );
}

export default App;
