import React, { useEffect, useState } from 'react';
import './styles.css';

function RenderSite({ title = 'Simulação do Sistema Solar' }) {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    document.title = title;

    fetch('/index.html')
      .then(response => response.text())
      .then(html => {
        setHtmlContent(html);
        const container = document.getElementById('external-page-container');
        container.innerHTML = html;

        const script = container.querySelector('script');
        if (script) {
          const newScript = document.createElement('script');
          newScript.textContent = script.textContent;
          container.appendChild(newScript);
        }
      });
  }, [title]);

  return <div id="external-page-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export default RenderSite;